<template>
  <b-card title="Diplomas Prices">
    <b-tabs>
      <b-tab
        :active="currentRouteName=='classical-Diplomas-price'"
        @click="$router.push('/classical-Diplomas-price')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Classical Diplomas Prices</span>
        <!-- <a  href="/classical-Diplomas-price">Classical Course Prices</a> -->
        </template>

      </b-tab>
      <b-tab
        :active="currentRouteName=='online-Diplomas-price'"
        @click="$router.push('/online-Diplomas-price')"
      >
        <template #title>
          <feather-icon icon="PlusIcon" />
          <span>Online Diplomas Prices</span>
        <!-- <a  href="/online-Diplomas-price">Online Course Prices</a> -->
        </template>

        <!-- <OnlineCoursePrice @add-price="getData"></OnlineCoursePrice> -->
      </b-tab>
      <b-tab
        :active="currentRouteName=='all-Diplomas-prices'"
        @click="$router.push('/all-Diplomas-prices')"
      >
        <template #title>
          <feather-icon icon="HomeIcon" />
          <!-- <a  href="/all-Diplomas-prices"> Prices</a> -->
          <span>Diplomas Prices</span>
        </template>

      <!-- <ViewPrice ref="allData"></ViewPrice> -->
      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <ClassicalCoursePrices v-if="route.name === 'classical-course-price' " />

      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>

  </b-card>
</template>

<script>
import {
  BTabs, BTab, BCardText, BCard,
} from 'bootstrap-vue'

import { ref } from '@vue/composition-api'
import ClassicalCoursePrices from './ClassicalCoursePrices.vue'
import OnlineCoursePrice from './OnlineCoursePrice.vue'
import ViewPrice from './ViewPrice.vue'

export default {
  components: {
    BCard,
    BCardText,
    BTabs,
    BTab,
    ClassicalCoursePrices,
    OnlineCoursePrice,

    ViewPrice,

  },
  setup() {
    const allData = ref()

    const getData = () => {
      allData.value.refetchData()
    }
    return {
      getData,
      allData,

    }
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>
